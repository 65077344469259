<template>
  <component :is="component" class="pt-8 pb-10 pt-lg-12 pb-lg-16">
    <TitleWidget v-if="title" class="mb-8">{{ title }}</TitleWidget>

    <LoadingWidget v-if="loading" />

    <template v-else>
      <slot />
    </template>
  </component>
</template>

<script>
import LoadingWidget from "../widgets/LoadingWidget.vue";
import TitleWidget from "../widgets/TitleWidget.vue";
import { VContainer } from "vuetify/lib/components";

export default {
  props: {
    title: String,
    loading: {
      type: Boolean,
      default: false,
    },
    container: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    component() {
      return this.container ? "v-container" : "div";
    },
  },
  components: {
    LoadingWidget,
    TitleWidget,
    VContainer,
  },
};
</script>
