<template>
  <v-card class="item" :to="to" tile flat>
    <img
      class="d-block item-img"
      :src="files + img + '?resize=1&w=300'"
      :alt="title"
      width="100%"
    />

    <div
      class="text-h6 text-md-h5 text-center font-weight-bold pa-5 item-title"
    >
      {{ title }}
    </div>
  </v-card>
</template>

<script>
export default {
  props: {
    img: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    to: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.item {
  height: 100%;
  &-img {
    aspect-ratio: 9 / 12;
    -o-object-fit: contain;
    object-fit: contain;
    border: 1px solid #e0e0e0;
  }
}
</style>
