<template>
  <transition name="fade">
    <v-container v-if="items.length" class="items">
      <template v-for="(item, key) in items">
        <ProductCategoryTemplate
          :key="key"
          v-bind="item"
          :right="key % 2 === 0"
        />
      </template>
    </v-container>
  </transition>
</template>

<script>
import ProductCategoryTemplate from "../templates/ProductCategoryTemplate.vue";
import { formatToURL } from "../../utils/formatToURL";

const _config = {
  mod: "productsCategories",
  path: "/produtos",
};

export default {
  computed: {
    items() {
      return this.$rest(_config.mod).list.map(({ id, img, title, text }) => ({
        img,
        title,
        text,
        to: `${_config.path}/${formatToURL(title)}-${id}`,
      }));
    },
  },
  components: {
    ProductCategoryTemplate,
  },
};
</script>

<style lang="scss" scoped>
.items {
  margin-top: 120px;

  @media screen and (max-width: 960px) {
    margin-top: 80px;
  }

  @media screen and (max-width: 600px) {
    margin-top: 60px;
  }

  .item {
    margin-bottom: 120px;

    @media screen and (max-width: 960px) {
      margin-bottom: 80px;
    }

    @media screen and (max-width: 600px) {
      margin-bottom: 60px;
    }
  }
}
</style>
