<template>
  <FormResponsePageTemplate :intro="intro" :text="text">
  </FormResponsePageTemplate>
</template>

<script>
import FormResponsePageTemplate from "../components/templates/FormResponsePageTemplate.vue";

export default {
  data: () => ({
    intro: "Sua mensagem foi enviada com sucesso.",
    text: "Muito obrigado! Entraremos em contato em breve.",
  }),
  components: { FormResponsePageTemplate },
};
</script>
