<template>
  <v-container class="py-10 py-md-13 py-lg-16">
    <TitleWidget>Produtos - {{ categoryTitle }}</TitleWidget>
    <transition name="fade">
      <v-row v-if="items.length" class="mt-6 mt-md-8">
        <template v-for="(item, key) in items">
          <v-col v-if="item" :key="key" cols="12" sm="6" md="4">
            <ProductCategoryCard v-bind="item" />
          </v-col>
        </template>
      </v-row>
    </transition>
  </v-container>
</template>

<script>
import TitleWidget from "../components/widgets/TitleWidget.vue";
import ProductCategoryCard from "../components/cards/ProductCategoryCard.vue";
import { formatToURL } from "../utils/formatToURL";

const _config = {
  mod: "productsSubcategories",
  path: "/produtos",
};

export default {
  created() {
    this.$rest(_config.mod).get();
  },
  computed: {
    categoryId() {
      return this.$route.params.category.replace(/^.*-([0-9]+)$/, "$1");
    },
    itemsByCategory() {
      return this.$rest(_config.mod).list.filter(
        ({ category }) => category.id == this.categoryId
      );
    },
    items() {
      return this.itemsByCategory.map(({ id, img, title, category }) => ({
        id,
        img,
        title,
        to: `${_config.path}/${formatToURL(category.title)}-${
          category.id
        }/${formatToURL(title)}-${id}`,
      }));
    },
    categoryTitle() {
      return this.itemsByCategory && this.itemsByCategory[0]
        ? this.itemsByCategory[0].category.title
        : null;
    },
  },
  components: {
    TitleWidget,
    ProductCategoryCard,
  },
};
</script>
