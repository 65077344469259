<template>
  <v-card class="item" :to="to" flat tile>
    <img
      class="d-block item-img"
      :src="files + img + '?resize=1&w=600'"
      :alt="title"
      width="100%"
    />

    <div
      class="
        text-body-1 text-md-h6 text-center text-uppercase
        font-weight-bold
        pa-4
      "
    >
      {{ title }}
    </div>
  </v-card>
</template>

<script>
export default {
  props: {
    img: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    to: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.item {
  &-img {
    aspect-ratio: 16 / 10;
    -o-object-fit: cover;
    object-fit: cover;
  }
}
</style>
