var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{class:{ 'header-bordered': _vm.$route.path !== '/esquadrias' },attrs:{"color":"white","elevate-on-scroll":"","height":"130px","app":""}},[_c('v-container',{staticClass:"py-0"},[_c('v-sheet',{staticClass:"d-flex justify-space-between align-center",attrs:{"width":"100%","height":"100%","color":"transparent"}},[_c('router-link',{attrs:{"to":"/esquadrias"}},[(_vm.informations.img)?_c('img',{staticClass:"d-block",attrs:{"src":_vm.files + _vm.informations.img,"alt":_vm.informations.name,"height":"80px"}}):_vm._e()]),_c('div',{staticClass:"d-flex align-center"},[_c('div',_vm._l((_vm.menu),function(ref,keyMenu){
var text = ref.text;
var to = ref.to;
var dropdown = ref.dropdown;
return _c('div',{key:keyMenu,staticClass:"d-inline-flex"},[(!dropdown)?_c('v-btn',{staticClass:"\n                grey--text\n                text-caption text-lg-body-1\n                font-weight-medium\n                px-2 px-lg-5\n                py-5\n                nav-link\n              ",attrs:{"to":to,"depressed":"","tile":"","text":""}},[_vm._v(" "+_vm._s(text)+" ")]):_c('v-menu',{attrs:{"content-class":"white elevation-0 dropdown-mega-menu","offset-y":"","eager":"","tile":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
var value = ref.value;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"\n                    text-caption text-lg-body-1\n                    font-weight-medium\n                    px-2 px-lg-5\n                    py-5\n                    nav-link\n                  ",class:{
                    'grey--text': !value,
                    'primary--text': value,
                  },attrs:{"active-class":"nav-link-active","depressed":"","tile":"","text":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(text)+" "),_c('v-icon',{staticClass:"ml-3",attrs:{"size":"12"}},[_vm._v(" fa-caret-down ")])],1)]}}],null,true)},[_c('v-container',{staticClass:"py-0"},[_c('v-row',{staticClass:"my-0"},[_vm._l((dropdown),function(ref){
                  var id = ref.id;
                  var img = ref.img;
                  var text = ref.text;
                  var to = ref.to;
return [_c('v-col',{key:to,staticClass:"pt-10 pb-16",attrs:{"cols":"12","sm":"6","md":"4"}},[_c('div',{staticClass:"pa-4 position-relative category"},[_c('div',{staticClass:"d-flex justify-center category-title"},[_c('span',{staticClass:"\n                              white\n                              secondary--text\n                              text-h5 text-uppercase\n                              font-weight-black\n                              px-6\n                            "},[_vm._v(" "+_vm._s(text)+" ")])]),(img)?_c('img',{staticClass:"d-block category-img",attrs:{"src":_vm.files + img + '?resize=1&w=200',"alt":text}}):_vm._e(),_c('div',{staticClass:"subitems"},[_c('v-list',{attrs:{"tile":""}},[_vm._l((_vm.getSubitems(id)),function(ref){
                  var title = ref.title;
                  var path = ref.path;
return [_c('v-list-item',{directives:[{name:"hover",rawName:"v-hover",value:(['div', 'primary--text']),expression:"['div', 'primary--text']"}],key:path,staticClass:"grey--text font-weight-medium",attrs:{"to":to + path}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(title)+" ")])],1)]})],2)],1)])])]})],2)],1)],1)],1)}),0)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }