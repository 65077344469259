<template>
  <div class="py-10 py-md-13 py-lg-16 position-relative item">
    <div v-if="item.title" class="grid-list">
      <div class="d-flex align-center position-relative">
        <v-sheet
          class="
            d-flex
            justify-center
            align-center
            white--text
            pa-6 pa-lg-10
            pr-lg-16
            mx-auto
            mr-md-0
          "
          color="transparent"
          max-width="680px"
        >
          <div>
            <div
              class="
                primary--text
                text-h4 text-md-h2 text-lg-h1 text-center text-md-left
              "
            >
              <span class="font-secondary">{{ item.title }}</span>
            </div>

            <div
              v-if="!smAndDown && item.text"
              class="
                primary--text
                text-body-2
                pl-md-13 pl-lg-16
                mt-3 mt-md-5
                ck-content
                item-text
              "
              v-html="item.text"
            ></div>
          </div>
        </v-sheet>
      </div>

      <div v-if="item.img || item.video">
        <div v-if="item.video" class="position-relative item-video">
          <video
            @click="(e) => playVideo(e)"
            class="d-block cursor-pointer"
            :src="files + item.video"
            width="100%"
            height="100%"
            loop
          ></video>

          <transition name="fade">
            <div v-if="!isPlaying" class="play-icon">
              <v-icon color="white" size="50px"> fa-play </v-icon>
            </div>
          </transition>
        </div>

        <img
          v-else
          class="d-block item-img"
          :src="files + item.img + '?resize=1&w=' + (xsOnly ? 600 : 960)"
          :alt="item.title"
          width="100%"
          height="100%"
        />

        <div
          v-if="smAndDown && item.text"
          class="
            primary--text
            text-body-2 text-center
            px-3
            pl-md-13 pl-lg-16
            pr-md-0
            mt-5
            ck-content
            item-text
          "
          v-html="item.text"
        ></div>
      </div>
    </div>

    <v-container
      v-if="item.mission || item.vision"
      class="pt-10 pt-md-13 pt-lg-16"
    >
      <v-row justify="center" justify-lg="space-around">
        <v-col cols="12" md="6" lg="5">
          <div class="d-flex align-start">
            <v-icon class="mt-4" color="#FCC05D" size="46"> fa-rocket </v-icon>

            <div class="pl-5 pl-md-10">
              <div class="text-h6 text-uppercase">Missão</div>

              <div
                class="text-body-2 mt-2 ml-4 ml-md-9 item-text ck-content"
                v-html="item.mission"
              ></div>
            </div>
          </div>
        </v-col>

        <v-col cols="12" md="6" lg="5">
          <div class="d-flex align-start">
            <v-icon class="mt-4" color="#FCC05D" size="46"> fa-eye </v-icon>

            <div class="pl-5 pl-md-10">
              <div class="text-h6 text-uppercase">Visão</div>

              <div
                class="text-body-2 mt-2 ml-4 ml-md-9 item-text ck-content"
                v-html="item.vision"
              ></div>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
const _config = {
  mod: "plannedEnvironmentAbout",
};

const _data = {
  isPlaying: false,
};

export default {
  data: () => ({
    ..._data,
  }),
  created() {
    this.$rest(_config.mod).get({
      save: (state, data) => (state.item = data),
    });
  },
  computed: {
    item() {
      return this.$rest(_config.mod).item;
    },
    mdOnly() {
      return this.$vuetify.breakpoint.mdOnly;
    },
    smAndDown() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    xsOnly() {
      return this.$vuetify.breakpoint.xsOnly;
    },
  },
  methods: {
    playVideo(e) {
      const video = e.target;

      if (video.paused) {
        video.play();

        setTimeout(() => {
          this.isPlaying = true;
        }, 100);
      } else {
        video.pause();
        this.isPlaying = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.item {
  &:before,
  &:after {
    background-size: contain;
    background-repeat: no-repeat;
    content: "";
    height: 100%;
    left: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    width: 100%;

    @media screen and (max-width: 1264px) {
      height: 0;
    }
  }

  &:before {
    background-image: url(../../assets/img/bg-left.png);
    background-position: left bottom;
    bottom: -150px;
  }

  &:after {
    background-image: url(../../assets/img/bg-right.png);
    background-position: right top;
    top: -70%;
  }

  .grid-list {
    display: grid;
    gap: 0;
    grid-template-columns: 47% 53%;

    @media screen and (max-width: 960px) {
      grid-template-columns: 1fr;
    }

    .item-video {
      .play-icon {
        bottom: 50px;
        left: 50px;
        pointer-events: none;
        position: absolute;
      }
    }

    .item-video video,
    .item-img {
      aspect-ratio: 16 / 9;
      display: block;
      -o-object-fit: cover;
      object-fit: cover;

      @media screen and (min-width: 960.001px) and (max-width: 1264px) {
        aspect-ratio: 1;
      }
    }
  }

  .item-text {
    letter-spacing: 2.8px !important;
    line-height: 1.7;
  }
}
</style>
