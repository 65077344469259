<template>
  <v-checkbox
    :rules="rules"
    name="policy_privacy"
    on-icon="fa fa-check-square"
    off-icon="far fa-square"
    class="mt-0 policy-privacy"
  >
    <template #label>
      <div>
        Eu li e concordo com a
        <a @click="showPrivacy">Política de Privacidade</a>.
      </div>
    </template>
  </v-checkbox>
</template>

<script>
import { requiredValidation } from "../validations/requiredValidation";

export default {
  data: () => ({
    rules: [requiredValidation("Você precisa aceitar para continuar.")],
  }),
  methods: {
    showPrivacy: () => window.showPrivacyPolicy(),
  },
};
</script>
