<template>
  <v-text-field
    :rules="rules"
    v-bind="fieldProps"
    name="name"
    label="Nome"
    autocomplete="name"
  ></v-text-field>
</template>

<script>
import { requiredValidation } from "../validations/requiredValidation";
import { mixin } from "./mixin";

export default {
  data: () => ({
    rules: [requiredValidation("Digite seu nome.")],
  }),
  mixins: [mixin],
};
</script>
