<template>
  <v-text-field
    :rules="rules"
    v-bind="fieldProps"
    v-mask="['(##) ####-####', '(##) #####-####']"
    name="tel"
    type="tel"
    label="Telefone"
    autocomplete="tel"
  ></v-text-field>
</template>

<script>
import { requiredValidation } from "../validations/requiredValidation";
import { telValidation } from "../validations/telValidation";
import { mixin } from "./mixin";
import { mask } from "vue-the-mask";

export default {
  data: () => ({
    rules: [requiredValidation("Digite seu telefone."), telValidation()],
  }),
  directives: {
    mask,
  },
  mixins: [mixin],
};
</script>
