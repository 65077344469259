var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('footer',{staticClass:"shell-footer"},[_c('v-sheet',{staticClass:"position-relative background-texture"},[_c('v-container',{staticClass:"py-6"},[_c('div',{staticClass:"grid-list"},[_c('div',{staticClass:"grid-list-item"},[_c('router-link',{attrs:{"to":"/esquadrias"}},[(_vm.informations.img)?_c('img',{staticClass:"d-block mx-auto mx-md-0",attrs:{"src":_vm.files + _vm.informations.img,"height":"90px"}}):_vm._e()])],1),_c('div',{staticClass:"d-flex flex-column align-start grid-list-item"},[_c('v-sheet',{staticClass:"\n              text-body-2 text-md-subtitle-2\n              font-weight-bold\n              primary--text\n            ",attrs:{"width":"100%","color":"transparent"}}),_vm._l((_vm.contacts),function(item,keyContact){return _c('div',{key:keyContact},[(!Array.isArray(item))?_c('v-btn',{staticClass:"primary--text text-body-2 text-none btn-contact",attrs:{"height":"auto","min-height":"36px","href":item.link,"target":item.target,"title":item.name,"depressed":"","color":"transparent"}},[(item.icon)?_c('v-icon',{staticClass:"mr-2",attrs:{"size":item.size}},[_vm._v(" "+_vm._s(item.icon)+" ")]):_vm._e(),_c('span',{domProps:{"innerHTML":_vm._s(item.text)}})],1):_c('div',{staticClass:"d-flex flex-column align-start"},_vm._l((item),function(ref,keyContactItem){
var name = ref.name;
var text = ref.text;
var link = ref.link;
var target = ref.target;
var icon = ref.icon;
var size = ref.size;
return _c('v-btn',{key:keyContactItem,staticClass:"primary--text text-body-2 text-none btn-contact",attrs:{"height":"auto","min-height":"36px","href":link,"target":target,"title":name,"depressed":"","color":"transparent"}},[(icon)?_c('v-icon',{staticClass:"mr-2",attrs:{"size":size}},[_vm._v(" "+_vm._s(icon)+" ")]):_vm._e(),_c('span',{domProps:{"innerHTML":_vm._s(text)}})],1)}),1)],1)}),_c('div',{staticClass:"d-flex flex-column align-start"},[_c('v-btn',{staticClass:"primary--text text-body-2 text-none btn-contact",attrs:{"height":"auto","min-height":"36px","to":"/politica-de-privacidade","color":"transparent","depressed":""}},[_c('v-icon',{staticClass:"mr-2",attrs:{"size":18}},[_vm._v(" fa-shield ")]),_vm._v(" Política de Privacidade ")],1)],1)],2),_c('div',{staticClass:"grid-item"},[_c('div',{staticClass:"d-flex justify-center justify-sm-end align-center"},_vm._l((_vm.social),function(ref,keySocial){
var text = ref.text;
var link = ref.link;
var icon = ref.icon;
var size = ref.size;
return _c('div',{key:keySocial,staticClass:"d-inline-flex"},[_c('v-btn',{staticClass:"text-caption text-lg-body-1 primary--text ml-2",attrs:{"href":link,"target":"_blank","min-width":"auto","color":"transparent","small":"","title":text,"depressed":"","rounded":"","fab":""}},[_c('v-icon',{attrs:{"color":"primary","size":size}},[_vm._v(_vm._s(icon))])],1)],1)}),0)])])])],1),_c('v-sheet',{attrs:{"color":"primary"}},[_c('v-container',{staticClass:"\n        pt-0\n        pb-5\n        d-flex\n        flex-column flex-sm-row\n        justify-space-between\n        align-center\n        text-center\n      "},[_c('div',{staticClass:"white--text text-caption mt-5"},[_vm._v(" Copyright © "),_c('b',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.year)+" "+_vm._s(_vm.informations.name))]),_vm._v(". "),_c('span',{staticClass:"d-block d-sm-inline-block"},[_vm._v(" Todos os direitos reservados. ")])]),_c('a',{staticClass:"d-flex mt-5",attrs:{"href":"https://www.mrxweb.com.br/","target":"_blank"}},[_c('img',{staticClass:"shell-footer-logo",attrs:{"src":require("../../assets/img/mrxweb.svg"),"alt":"MRX Web Sites, Publicidade, Marketing Digital, Produção de Vídeos - Sinop/MT","title":"MRX Web Sites, Publicidade, Marketing Digital, Produção de Vídeos - Sinop/MT","height":"20px"}})])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }