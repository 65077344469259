<template>
  <div v-if="items.length" class="position-relative slides slop">
    <hooper-container
      class="slides-container"
      style="height: auto"
      ref="carousel"
      :wheelControl="false"
      :mouseDrag="items.length > 1"
      :touchDrag="items.length > 1"
      :infiniteScroll="items.length > 1"
      :autoPlay="items.length > 1"
      :playSpeed="5000"
      :transition="500"
    >
      <hooper-slide
        class="item"
        v-for="({ img_desktop, img_mobile, link, target }, keySlide) in items"
        :key="keySlide"
      >
        <v-card :href="link" :target="target ? '_blank' : '_self'" flat tile>
          <img
            :src="files + (xs && img_mobile ? img_mobile : img_desktop)"
            class="d-block item-img"
            width="100%"
          />

          <div
            class="
              d-flex
              align-lg-center
              pt-10 pt-lg-0
              pointer-events-none
              item-content
            "
          >
            <v-container class="py-0">
              <div class="align-center justify-center align-lg-start grid-list">
                <div class="d-flex align-start mt-lg-8">
                  <router-link class="pointer-events-initial" to="/ambientes">
                    <img
                      class="d-block"
                      :src="files + informations.logo"
                      :alt="informations.title"
                      width="100%"
                    />
                  </router-link>
                </div>

                <div class="d-flex flex-column">
                  <div v-if="social.length" class="d-flex justify-end">
                    <v-btn
                      v-for="{ link, icon } in social"
                      :key="link"
                      class="pointer-events-initial"
                      color="transparent"
                      :href="link"
                      target="_blank"
                      depressed
                      small
                      fab
                    >
                      <v-icon size="28" color="white">
                        {{ icon.replace("facebook-f", "facebook") }}
                      </v-icon>
                    </v-btn>
                  </div>

                  <v-sheet
                    v-if="!mdAndDown"
                    class="
                      pa-12
                      my-10 my-md-13 my-lg-16
                      elevation-1
                      pointer-events-initial
                    "
                    color="#F5F5F5"
                  >
                    <div
                      class="primary--text text-h5 text-center font-weight-bold"
                    >
                      Faça um orçamento!
                    </div>

                    <BudgetForm
                      class="mt-3"
                      :action="server + '/rest/email/budget'"
                      response-text="Agradecemos seu contato, responderemos em breve."
                    />
                  </v-sheet>
                </div>
              </div>
            </v-container>
          </div>
        </v-card>
      </hooper-slide>
    </hooper-container>
  </div>
</template>

<script>
import BudgetForm from "../../components/forms/BudgetForm.vue";
import { getSocial } from "../../utils/getSocial";

const _config = {
  mod: "plannedEnvironmentSlides",
};

const _data = {
  carouselData: 0,
};

export default {
  data: () => ({
    ..._data,
  }),
  created() {
    this.$rest(_config.mod).get();
  },
  computed: {
    informations() {
      return this.$rest("plannedEnvironmentInformations").item;
    },
    social() {
      return getSocial(this.informations);
    },
    items() {
      return this.$rest(_config.mod).list;
    },
    mdAndDown() {
      return this.$vuetify.breakpoint.mdAndDown;
    },
    xs() {
      return this.$vuetify.breakpoint.xsOnly;
    },
  },
  components: {
    BudgetForm,
  },
};
</script>

<style lang="scss" scoped>
.slides-container::v-deep {
  ul,
  ol {
    padding: 0 !important;
  }
}

.slides {
  .item {
    &-img {
      object-fit: cover;
      -o-object-fit: cover;
      height: 880px;

      @media screen and (max-width: 1264px) {
        height: auto;
        aspect-ratio: 1920 / 880;
      }

      @media screen and (max-width: 600px) {
        aspect-ratio: 600 / 600;
        height: auto;
      }
    }

    &-content {
      bottom: 0;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;

      .grid-list {
        display: grid;
        gap: 0 110px;
        grid-template-columns: auto 460px;

        @media screen and (max-width: 1264px) {
          grid-template-columns: 40% auto;
        }
      }
    }
  }
}
</style>
