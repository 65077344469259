<template>
  <BaseForm
    class="budget-form"
    :class="{ small: small }"
    :action="action"
    :response-to="responseTo"
    :response-text="responseText"
    gtm-submit-event="form_submit_budget-us"
  >
    <template #fields>
      <NameField />
      <TelField />
      <EmailField />
      <CityField />
      <PolicyPrivacyField />
    </template>
  </BaseForm>
</template>

<script>
import BaseForm from "./BaseForm.vue";
import NameField from "./fields/budget/NameField.vue";
import EmailField from "./fields/budget/EmailField.vue";
import TelField from "./fields/budget/TelField.vue";
import CityField from "./fields/budget/CityField.vue";
import PolicyPrivacyField from "./fields/PolicyPrivacyField.vue";

export default {
  props: {
    action: {
      type: String,
      required: true,
    },
    small: {
      type: Boolean,
      default: false,
    },
    responseTo: String,
    responseText: String,
  },
  components: {
    BaseForm,
    NameField,
    EmailField,
    TelField,
    CityField,
    PolicyPrivacyField,
  },
};
</script>

<style lang="scss">
.budget-form {
  .base-form-fields {
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 12px;

    .v-text-field {
      .v-input__slot {
        box-shadow: 0px 1.463px 3.99px 0px rgba(0, 0, 0, 0.02),
          0px 4.914px 13.402px 0px rgba(0, 0, 0, 0.03),
          0px 10px 40px 0px rgba(0, 0, 0, 0.03);
      }
    }
  }
}
</style>
