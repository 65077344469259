<template>
  <div class="position-relative slides slop">
    <LoadingWidget v-if="loading" />

    <transition name="fade">
      <div v-if="!loading && items.length">
        <hooper-container
          class="slides-container"
          style="height: auto"
          ref="carousel"
          :wheelControl="false"
          :mouseDrag="items.length > 1"
          :touchDrag="items.length > 1"
          :infiniteScroll="items.length > 1"
          :autoPlay="items.length > 1"
          @slide="updateCarousel"
          :playSpeed="5000"
          :transition="500"
        >
          <hooper-slide
            class="item"
            v-for="(
              { img_desktop, img_mobile, link, target }, keySlide
            ) in items"
            :key="keySlide"
          >
            <a v-if="link" :href="link" :target="target ? '_blank' : '_self'">
              <img
                :src="files + (xs && img_mobile ? img_mobile : img_desktop)"
                class="d-block item-img"
                width="100%"
              />
            </a>

            <img
              v-else
              :src="files + (xs && img_mobile ? img_mobile : img_desktop)"
              class="d-block item-img"
              width="100%"
            />
          </hooper-slide>
        </hooper-container>

        <div
          v-if="items.length > 1"
          class="d-flex justify-space-between px-3 px-md-4 px-lg-5 slides-nav"
        >
          <a
            class="d-flex align-center justify-center pr-1 slides-nav-item"
            @click.prevent="slidePrev"
          >
            <v-icon color="white" :size="xs ? 30 : 40"> fa-angle-left </v-icon>
          </a>
          <a
            class="d-flex align-center justify-center pl-1 slides-nav-item"
            @click.prevent="slideNext"
          >
            <v-icon color="white" :size="xs ? 30 : 40"> fa-angle-right </v-icon>
          </a>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import LoadingWidget from "../widgets/LoadingWidget.vue";

const _config = {
  mod: "slides",
};

export default {
  data: () => ({
    loading: true,
    carouselData: 0,
  }),
  computed: {
    items() {
      return this.$rest(_config.mod).list;
    },
    xs() {
      return this.$vuetify.breakpoint.xsOnly;
    },
  },
  mounted() {
    this.$rest(_config.mod)
      .get()
      .then(() => (this.loading = false));
  },
  methods: {
    slidePrev() {
      this.$refs.carousel.slidePrev();
    },
    slideNext() {
      this.$refs.carousel.slideNext();
    },
    updateCarousel(payload) {
      this.myCarouselData = payload.currentSlide;
    },
  },
  watch: {
    carouselData() {
      this.$refs.carousel.slideTo(this.carouselData);
    },
  },
  components: {
    LoadingWidget,
  },
};
</script>

<style lang="scss" scoped>
.slides {
  aspect-ratio: 1920 / 680;

  @media screen and (max-width: 600px) {
    aspect-ratio: 600 / 600;
  }

  .item {
    &-img {
      aspect-ratio: 1920 / 680;
      object-fit: cover;
      -o-object-fit: cover;

      @media screen and (max-width: 600px) {
        aspect-ratio: 600 / 600;
      }
    }
  }

  &-nav {
    height: 0;
    left: 0;
    margin-top: -25px;
    position: absolute;
    top: 50%;
    width: 100%;

    @media screen and (max-width: 600px) {
      margin-top: -20px;
    }

    &-item {
      border-radius: 50%;
      height: 50px;
      width: 50px;

      @media screen and (max-width: 600px) {
        height: 40px;
        width: 40px;
      }
    }
  }
}
</style>

<style lang="scss">
.slides-container {
  ul,
  ol {
    padding: 0 !important;
  }
}
</style>
