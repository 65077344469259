import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import FramesHomeView from "../views/FramesHomeView.vue";
import PlannedEnvironmentHomeView from "../views/PlannedEnvironment/Index.vue";
import AboutView from "../views/AboutView.vue";
import PlannedFurnitureView from "../views/PlannedFurnitureView.vue";
import ProductsCategoriesView from "../views/ProductsCategoriesView.vue";
import ProductsSubcategoriesView from "../views/ProductsSubcategoriesView.vue";
import ProductsListView from "../views/ProductsListView.vue";
import ProductsItemView from "../views/ProductsItemView.vue";
import Error404View from "../views/Error404View.vue";
import PolicyPrivacyView from "../views/PolicyPrivacyView.vue";
import ContactView from "../views/ContactView.vue";
import ContactResponseView from "../views/ContactResponseView.vue";
// import UnderConstructionView from "../views/UnderConstructionView.vue";

Vue.use(VueRouter);

const routes = [
  {
    name: "Início",
    path: "/",
    component: HomeView,
  },
  {
    name: "Esquadrias",
    path: "/esquadrias",
    component: FramesHomeView,
    meta: {
      hasHeader: true,
      hasFooter: true,
    },
  },
  {
    name: "Quem Somos",
    path: "/quem-somos",
    component: AboutView,
    meta: {
      hasHeader: true,
      hasFooter: true,
    },
  },
  {
    name: "Produtos",
    path: "/produtos",
    component: ProductsCategoriesView,
    meta: {
      hasHeader: true,
      hasFooter: true,
    },
  },
  {
    name: "Produtos",
    path: "/produtos/:category",
    component: ProductsSubcategoriesView,
    meta: {
      hasHeader: true,
      hasFooter: true,
    },
  },
  {
    name: "Produtos",
    path: "/produtos/:category/:subcategory",
    component: ProductsListView,
    meta: {
      hasHeader: true,
      hasFooter: true,
    },
  },
  {
    name: "Produtos",
    path: "/produtos/:category/:subcategory/:item",
    component: ProductsItemView,
    meta: {
      hasHeader: true,
      hasFooter: true,
    },
  },
  {
    name: "Móveis Planejados",
    path: "/moveis-planejados",
    component: PlannedFurnitureView,
    meta: {
      hasHeader: true,
      hasFooter: true,
    },
  },
  {
    name: "Fale Conosco",
    path: "/fale-conosco",
    component: ContactView,
    meta: {
      hasHeader: true,
      hasFooter: true,
    },
  },
  {
    name: "Obrigado!",
    path: "/fale-conosco/obrigado",
    component: ContactResponseView,
    meta: {
      hasHeader: true,
      hasFooter: true,
    },
  },
  {
    name: "Política de Privacidade",
    path: "/politica-de-privacidade",
    component: PolicyPrivacyView,
    meta: {
      hasHeader: true,
      hasFooter: true,
    },
  },
  {
    path: "/erro404",
    name: "Erro 404",
    component: Error404View,
    meta: {
      hasHeader: true,
      hasFooter: true,
    },
  },
  {
    path: "*",
    name: "Erro 404",
    component: Error404View,
    meta: {
      hasHeader: true,
      hasFooter: true,
    },
  },
  {
    name: "Ambientes",
    path: "/ambientes",
    component: PlannedEnvironmentHomeView,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: "smooth",
      };
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
  routes,
});

export default router;
