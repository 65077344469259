<template>
  <v-sheet min-height="100%">
    <v-container class="py-10 py-md-13 py-lg-16 item">
      <TitleWidget>
        {{ item.title || "Móveis Planejados" }}
      </TitleWidget>

      <LoadingWidget v-if="loading" />

      <transition name="fade">
        <div v-if="!loading">
          <div v-if="item.title">
            <CoolLightBox
              :items="images"
              :index="index"
              @close="index = null"
            />

            <div
              v-if="item.text"
              class="
                grey--text
                text--darken-4 text-body-1
                mt-6
                ck-content
                item-text
              "
              v-html="item.text"
            ></div>

            <div class="mt-6 mt-md-8 grid-list-images">
              <div
                class="grid-list-images-item"
                v-for="({ src }, i) in images"
                :key="i"
                @click="index = i"
              >
                <img
                  :src="src + '?resize=1&w=' + (xsOnly ? 260 : 600)"
                  class="d-block"
                />
              </div>
            </div>
          </div>

          <div v-else>
            <v-alert class="mt-8" type="info">
              Nenhum conteúdo adicionado até o momento.
            </v-alert>
          </div>
        </div>
      </transition>
    </v-container>
  </v-sheet>
</template>

<script>
import LoadingWidget from "../components/widgets/LoadingWidget.vue";
import TitleWidget from "../components/widgets/TitleWidget.vue";
import CoolLightBox from "vue-cool-lightbox";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";

const _config = {
  mod: "plannedFurniture",
};

export default {
  data: () => ({
    loading: true,
    index: null,
  }),
  created() {
    this.$rest(_config.mod)
      .get({
        save: (state, data) => (state.item = data),
      })
      .then(() => (this.loading = false));
  },
  computed: {
    item() {
      return this.$rest(_config.mod).item;
    },
    images() {
      const images = [];
      this.item.img?.list.map(({ path, title }) => {
        if (path !== this.item.img?.featured?.path)
          images.push({ src: this.files + path, title });
      });

      return images;
    },
    xsOnly() {
      return this.$vuetify.breakpoint.xsOnly;
    },
  },
  components: {
    LoadingWidget,
    TitleWidget,
    CoolLightBox,
  },
};
</script>

<style lang="scss" scoped>
.grid-list-images {
  display: grid;
  gap: 2px;
  grid-template-columns: 1fr 1fr 1fr;

  @media screen and (max-width: 960px) {
    grid-template-columns: 1fr 1fr;
  }

  .grid-list-images-item {
    img {
      aspect-ratio: 360 / 210;
      cursor: pointer;
      object-fit: cover;
      -o-object-fit: cover;
      width: 100%;
    }
  }
}
</style>
