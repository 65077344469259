<template>
  <div>
    <Header />
    <Environments />
    <About />
    <Footer />
  </div>
</template>

<script>
import Header from "./Header.vue";
import Environments from "./Environments.vue";
import About from "./About.vue";
import Footer from "./Footer.vue";

const _config = {
  mod: "plannedEnvironmentInformations",
};

const _data = {
  loading: true,
};

export default {
  data: () => ({
    ..._data,
  }),
  created() {
    this.$rest(_config.mod)
      .get({
        save: (state, data) => (state.item = data),
      })
      .finally(() => (this.loading = false));
  },
  computed: {
    informations() {
      return this.$rest(_config.mod).item;
    },
  },
  components: {
    Header,
    Environments,
    About,
    Footer,
  },
};
</script>
