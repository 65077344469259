<template>
  <div>
    <Desktop v-if="!mobile" :menu="menu" />
    <Mobile v-else :menu="menu" />
  </div>
</template>

<script>
import Desktop from "./Desktop.vue";
import Mobile from "./Mobile.vue";
import { formatToURL } from "../../../utils/formatToURL";

export default {
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    categories() {
      return this.$rest("productsCategories").list.map(
        ({ id, img_menu, title }) => ({
          id,
          img: img_menu,
          text: title,
          to: `/produtos/${formatToURL(title)}-${id}`,
        })
      );
    },
    menu() {
      return [
        {
          text: "Início",
          to: "/esquadrias",
        },
        {
          text: "Quem Somos",
          to: "/quem-somos",
        },
        {
          text: "Produtos",
          dropdown: this.categories,
        },
        {
          text: "Móveis Planejados",
          to: "/moveis-planejados",
        },
        {
          text: "Fale Conosco",
          to: "/fale-conosco",
        },
      ];
    },
  },
  components: {
    Desktop,
    Mobile,
  },
};
</script>
