var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.items.length)?_c('div',{staticClass:"position-relative slides slop"},[_c('hooper-container',{ref:"carousel",staticClass:"slides-container",staticStyle:{"height":"auto"},attrs:{"wheelControl":false,"mouseDrag":_vm.items.length > 1,"touchDrag":_vm.items.length > 1,"infiniteScroll":_vm.items.length > 1,"autoPlay":_vm.items.length > 1,"playSpeed":5000,"transition":500}},_vm._l((_vm.items),function(ref,keySlide){
var img_desktop = ref.img_desktop;
var img_mobile = ref.img_mobile;
var link = ref.link;
var target = ref.target;
return _c('hooper-slide',{key:keySlide,staticClass:"item"},[_c('v-card',{attrs:{"href":link,"target":target ? '_blank' : '_self',"flat":"","tile":""}},[_c('img',{staticClass:"d-block item-img",attrs:{"src":_vm.files + (_vm.xs && img_mobile ? img_mobile : img_desktop),"width":"100%"}}),_c('div',{staticClass:"\n            d-flex\n            align-lg-center\n            pt-10 pt-lg-0\n            pointer-events-none\n            item-content\n          "},[_c('v-container',{staticClass:"py-0"},[_c('div',{staticClass:"align-center justify-center align-lg-start grid-list"},[_c('div',{staticClass:"d-flex align-start mt-lg-8"},[_c('router-link',{staticClass:"pointer-events-initial",attrs:{"to":"/ambientes"}},[_c('img',{staticClass:"d-block",attrs:{"src":_vm.files + _vm.informations.logo,"alt":_vm.informations.title,"width":"100%"}})])],1),_c('div',{staticClass:"d-flex flex-column"},[(_vm.social.length)?_c('div',{staticClass:"d-flex justify-end"},_vm._l((_vm.social),function(ref){
var link = ref.link;
var icon = ref.icon;
return _c('v-btn',{key:link,staticClass:"pointer-events-initial",attrs:{"color":"transparent","href":link,"target":"_blank","depressed":"","small":"","fab":""}},[_c('v-icon',{attrs:{"size":"28","color":"white"}},[_vm._v(" "+_vm._s(icon.replace("facebook-f", "facebook"))+" ")])],1)}),1):_vm._e(),(!_vm.mdAndDown)?_c('v-sheet',{staticClass:"\n                    pa-12\n                    my-10 my-md-13 my-lg-16\n                    elevation-1\n                    pointer-events-initial\n                  ",attrs:{"color":"#F5F5F5"}},[_c('div',{staticClass:"primary--text text-h5 text-center font-weight-bold"},[_vm._v(" Faça um orçamento! ")]),_c('BudgetForm',{staticClass:"mt-3",attrs:{"action":_vm.server + '/rest/email/budget',"response-text":"Agradecemos seu contato, responderemos em breve."}})],1):_vm._e()],1)])])],1)])],1)}),1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }