<template>
  <div>
    <LoadingWidget v-if="loading" />

    <transition name="fade">
      <v-container v-if="!loading && item.title" class="items">
        <ProductCategoryTemplate
          :img="item.img.featured.path"
          :title="item.title"
          :text="item.introduction"
          :to="path"
        />
      </v-container>
    </transition>
  </div>
</template>

<script>
import LoadingWidget from "../widgets/LoadingWidget.vue";
import ProductCategoryTemplate from "../templates/ProductCategoryTemplate.vue";

const _config = {
  mod: "plannedFurniture",
  path: "/moveis-planejados",
};

export default {
  data: () => ({
    loading: true,
    path: _config.path,
  }),
  created() {
    this.$rest(_config.mod)
      .get({
        save: (state, data) => (state.item = data),
      })
      .finally(() => (this.loading = false));
  },
  computed: {
    item() {
      return this.$rest(_config.mod).item;
    },
  },
  components: {
    LoadingWidget,
    ProductCategoryTemplate,
  },
};
</script>

<style lang="scss" scoped>
.items {
  margin-top: 120px;

  @media screen and (max-width: 960px) {
    margin-top: 80px;
  }

  @media screen and (max-width: 600px) {
    margin-top: 60px;
  }
}
</style>
