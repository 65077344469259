<template>
  <v-app>
    <LoadingWidget v-if="loading" />

    <template v-else>
      <HeaderShell v-if="$route.meta.hasHeader" />

      <v-main>
        <router-view v-bind="$route.meta" />
      </v-main>

      <FooterShell v-if="$route.meta.hasFooter" />
    </template>

    <CookieBannerWidget />

    <WhatsAppButtonWidget
      v-if="informations.whatsapp_float"
      :link="informations.whatsapp_float"
    />
  </v-app>
</template>

<script>
import HeaderShell from "./components/shell/header/HeaderShell.vue";
import FooterShell from "./components/shell/FooterShell.vue";
import CookieBannerWidget from "./components/widgets/CookieBannerWidget.vue";
import LoadingWidget from "./components/widgets/LoadingWidget.vue";
import WhatsAppButtonWidget from "./components/widgets/WhatsAppButtonWidget.vue";

export default {
  data: () => ({
    loading: true,
  }),
  created() {
    this.updateDocumentTitle();
  },
  mounted() {
    const informations = this.$rest("informations").get({
      save: (state, data) => (state.item = data),
    });

    const categories = this.$rest("productsCategories").get();

    Promise.all([informations, categories]).finally(
      () => (this.loading = false)
    );
  },
  computed: {
    informations() {
      return this.$rest("informations").item;
    },
    name() {
      return this.informations.name || "";
    },
  },
  watch: {
    $route() {
      this.updateDocumentTitle();
    },
    name() {
      this.updateDocumentTitle();
    },
  },
  methods: {
    updateDocumentTitle() {
      document.title = !this.name
        ? this.$route.name
        : `${this.$route.name} | ${this.name}`;
    },
  },
  components: {
    HeaderShell,
    FooterShell,
    CookieBannerWidget,
    LoadingWidget,
    WhatsAppButtonWidget,
  },
};
</script>
