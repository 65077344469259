<template>
  <div class="d-flex align-center position-relative item">
    <v-container class="position-relative">
      <v-row align="center" justify="space-around">
        <v-col cols="10" sm="6" md="5">
          <router-link to="/esquadrias">
            <img
              class="d-block"
              :src="files + informations.img"
              :alt="informations.name"
              width="100%"
            />
          </router-link>

          <div class="d-flex justify-center mt-5">
            <v-btn color="primary" to="/esquadrias" depressed> Acessar </v-btn>
          </div>
        </v-col>

        <v-col class="mt-10 mt-sm-0" cols="10" sm="6" md="5">
          <router-link to="/ambientes">
            <img
              class="d-block"
              :src="files + environmentInformations.logo"
              :alt="environmentInformations.name"
              width="100%"
            />
          </router-link>

          <div class="d-flex justify-center mt-5">
            <v-btn color="primary" to="/ambientes" depressed> Acessar </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  created() {
    this.$rest("informations").get({
      save: (state, data) => (state.item = data),
    });

    this.$rest("plannedEnvironmentInformations").get({
      save: (state, data) => (state.item = data),
    });
  },
  computed: {
    informations() {
      return this.$rest("informations").item;
    },
    environmentInformations() {
      return this.$rest("plannedEnvironmentInformations").item;
    },
  },
};
</script>

<style lang="scss" scoped>
.item {
  min-height: 100%;
  width: 100%;
}

.item::v-deep {
  .container {
    z-index: 5;
  }
}
</style>
