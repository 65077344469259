<template>
  <v-sheet min-height="100%">
    <v-container class="py-10 py-md-13 py-lg-16 item">
      <TitleWidget>
        {{ item.title || "Quem Somos" }}
      </TitleWidget>

      <LoadingWidget v-if="loading" />

      <transition name="fade">
        <div v-if="!loading">
          <v-row v-if="item.title" justify="center">
            <v-col cols="12">
              <v-sheet class="mx-auto" color="transparent" max-width="900">
                <img
                  v-if="item.img.featured.path"
                  class="d-block mt-6 mt-md-8 item-img"
                  :src="files + item.img.featured.path + '?resize=1&w=960'"
                  :alt="item.title"
                  width="100%"
                />
              </v-sheet>

              <div
                class="grey--text text-body-1 mt-6 mt-md-8 ck-content item-text"
                v-html="item.text"
              ></div>
            </v-col>

            <v-col v-if="images.length > 0" cols="12" class="mt-12">
              <TitleWidget small> Galeria de Fotos </TitleWidget>

              <CoolLightBox
                :items="images"
                :index="index"
                @close="index = null"
              />

              <div class="mt-8 images-grid-list">
                <img
                  class="d-block cursor-pointer"
                  @click="index = keyImages"
                  :src="img + '?resize=1&w=300'"
                  width="100%"
                  v-for="(img, keyImages) in images"
                  :key="keyImages"
                />
              </div>
            </v-col>
          </v-row>

          <v-row v-else>
            <v-col cols="12">
              <v-alert class="mt-8" type="info">
                Nenhum conteúdo adicionado até o momento.
              </v-alert>
            </v-col>
          </v-row>
        </div>
      </transition>
    </v-container>
  </v-sheet>
</template>

<script>
import LoadingWidget from "../components/widgets/LoadingWidget.vue";
import TitleWidget from "../components/widgets/TitleWidget.vue";
import CoolLightBox from "vue-cool-lightbox";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";

const _config = {
  mod: "about",
};

export default {
  data: () => ({
    loading: true,
    index: null,
  }),
  created() {
    this.$rest(_config.mod)
      .get({
        save: (state, data) => (state.item = data),
      })
      .then(() => (this.loading = false));
  },
  computed: {
    item() {
      return this.$rest(_config.mod).item;
    },
    images() {
      const images = [];
      this.item.img?.list.map(({ path }) => {
        if (
          (!this.item.video && path !== this.item.img?.featured?.path) ||
          this.item.video
        )
          images.push(this.files + path);
      });

      return images;
    },
  },
  components: {
    LoadingWidget,
    TitleWidget,
    CoolLightBox,
  },
};
</script>

<style lang="scss" scoped>
.subitems-text {
  line-height: 1.4 !important;
}

.images-grid-list {
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(4, 1fr);

  @media screen and (max-width: 960px) {
    gap: 5px;
    grid-template-columns: repeat(3, 1fr);
  }

  @media screen and (max-width: 600px) {
    grid-template-columns: repeat(2, 1fr);
  }

  img {
    aspect-ratio: 16 / 12;
    -o-object-fit: cover;
    object-fit: cover;
  }
}
</style>
