<template>
  <div
    v-if="item.title && images.length && icons.length"
    class="align-center py-10 py-md-13 py-lg-16 position-relative item"
  >
    <div class="ml-md-auto position-relative">
      <div class="white--text text-h4 text-md-h2 text-lg-h1 text-center">
        <span class="font-secondary">{{ item.title }}</span>
      </div>

      <v-sheet
        v-if="!smAndDown && selectedIcon && selectedIcon.img"
        class="
          d-flex
          flex-column
          align-center
          justify-center
          rounded-circle
          pa-16
          mt-4
          mx-auto
        "
        :height="smAndDown ? '150px' : '230px'"
        :width="smAndDown ? '150px' : '230px'"
      >
        <img
          class="d-block item-icon-large"
          :src="selectedIcon.img"
          height="100%"
          width="100%"
        />

        <div
          v-if="selectedIcon.title"
          class="
            text-h6 text-center text-uppercase
            font-weight-bold
            mt-2
            item-icon-large-title
          "
        >
          {{ selectedIcon.title }}
        </div>
      </v-sheet>
    </div>

    <div class="d-flex flex-column pl-md-16 position-relative">
      <div v-if="selectedImage">
        <img
          class="d-block item-image-large"
          :src="selectedImage"
          width="100%"
          height="100%"
        />
      </div>

      <v-sheet class="mt-5" color="transparent" :width="widthIcons">
        <CarouselWidget :items="icons" :itemsToShow="itemsToShow">
          <template #item="{ item, keyItem }">
            <v-card
              @click="
                selectedIcon = item;
                selectedImage = images[keyItem];
              "
              class="px-3"
              color="transparent"
              flat
              tile
            >
              <img
                class="d-block item-icon"
                :src="item.img"
                height="50px"
                width="100%"
              />

              <div
                v-if="item.title"
                class="
                  white--text
                  text-caption text-center text-uppercase
                  mt-3
                  item-icon-title
                "
              >
                {{ item.title }}
              </div>
            </v-card>
          </template>
        </CarouselWidget>
      </v-sheet>
    </div>
  </div>
</template>

<script>
import CarouselWidget from "../../components/widgets/CarouselWidget.vue";

const _config = {
  mod: "plannedEnvironmentEnvironments",
};

const _data = {
  selectedIcon: null,
  selectedImage: null,
  itemsToShow: {
    xs: 2.5,
    sm: 4,
    md: 4,
    lg: 6,
    xl: 8,
  },
};

export default {
  data: () => ({
    ..._data,
  }),
  created() {
    this.$rest(_config.mod).get({
      save: (state, data) => (state.item = data),
    });
  },
  computed: {
    item() {
      return this.$rest(_config.mod).item;
    },
    images() {
      if (!this.item.img || !this.item.img.list.length) return [];

      return this.item.img.list.map(({ path }) => {
        return this.files + path + "?resize=1&w=1200";
      });
    },
    icons() {
      if (!this.item.icons || !this.item.icons.list.length) return [];
      const totalImages =
        !this.item.img || !this.item.img.list.length
          ? 0
          : this.item.img.list.length;

      return this.item.icons.list
        .filter((item, index) => index < totalImages)
        .map(({ path, title }) => ({
          img: this.files + path + "?resize=1&w=1200",
          title,
        }));
    },
    smAndDown() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    widthIcons() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
          return "900px";
        case "lg":
          return "750px";
        default:
          return "100%";
      }
    },
  },
  watch: {
    icons(v) {
      if (!this.selectedIcon && v.length) this.selectedIcon = v[0];
    },
    images(v) {
      if (!this.selectedImage && v.length) this.selectedImage = v[0];
    },
  },
  components: {
    CarouselWidget,
  },
};
</script>

<style lang="scss" scoped>
.item {
  background: rgb(20, 24, 56);
  background: -moz-linear-gradient(
    90deg,
    rgba(20, 24, 56, 1) 0%,
    rgba(8, 10, 24, 1) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(20, 24, 56, 1) 0%,
    rgba(8, 10, 24, 1) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(20, 24, 56, 1) 0%,
    rgba(8, 10, 24, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#141838",endColorstr="#080a18",GradientType=1);
  display: grid;
  grid-template-columns: 30% 70%;

  @media screen and (max-width: 960px) {
    grid-template-columns: 100%;
  }

  &:before {
    background-image: url(../../assets/img/bg-left.png);
    background-repeat: no-repeat;
    background-size: contain;
    content: "";
    left: 0;
    height: 100%;
    pointer-events: none;
    position: absolute;
    top: -40%;
    width: 100%;
    z-index: 5;

    @media screen and (max-width: 1264px) {
      height: 0;
    }
  }

  > :first-child {
    margin-bottom: 110px;

    @media screen and (max-width: 960px) {
      margin-bottom: 30px;
    }
  }

  &-icon-large {
    filter: brightness(1) invert(1);
    -o-object-fit: contain;
    object-fit: contain;
  }

  &-icon-large-title {
    line-height: 1.2;
  }

  &-image-large {
    aspect-ratio: 1140 / 700;
    -o-object-fit: cover;
    object-fit: cover;
  }

  &-icon {
    -o-object-fit: contain;
    object-fit: contain;
  }

  &-icon-title {
    line-height: 1.2;
  }
}
</style>
