import Vue from "vue";
/**
 * @typedef Modifiers
 * @type {object}
 * @property {boolean} not
 */

/**
 * @typedef Value
 * @type {Object.<string, string>|[string, string]|string|{active: boolean}}
 */

/**
 *
 * @param {unknown} val
 * @returns {string}
 */
const getType = (val) => (Array.isArray(val) ? "array" : typeof val);

/**
 *
 * @param {HTMLElement} el
 * @param {string} selector
 * @returns {HTMLElement[]}
 */
const querySelectorAll = (el, selector) => {
  let collection = el.querySelectorAll(selector);
  if (/:scope/.test(selector)) collection = [...collection, el];

  return collection;
};

/**
 *
 * @param {HTMLElement} el
 * @param {Value} val
 * @returns {[HTMLElement[], string[]][]}
 */
const getEntries = (el, val) => {
  const type = getType(val);

  const entries = {
    string: (className) => [[[el], className.split(" ")]],
    array: ([selector, className]) => [
      [querySelectorAll(el, selector), className.split(" ")],
    ],
    object: (obj) =>
      Object.entries(obj).map(([selector, className]) => [
        querySelectorAll(el, selector),
        className.split(" "),
      ]),
  };

  return entries[type](val);
};

/**
 *
 * @param {(child: HTMLElement, classNameArr: string[]) => void} callback
 * @returns {(entries: [HTMLElement[], string[]][]) => void}
 */
const mutationEntries = (callback) => {
  return (entries) => {
    entries.forEach(([collection, classNameArr]) => {
      collection.forEach((child) => callback(child, classNameArr));
    });
  };
};

const addClasses = mutationEntries((child, classNameArr) =>
  classNameArr.forEach((className) => child.classList.add(className))
);

const removeClasses = mutationEntries((child, classNameArr) =>
  classNameArr.forEach((className) => child.classList.remove(className))
);

/**
 *
 * @param {Modifiers} modifiers
 * @returns {{
 *  pointerEnter: (entries: [HTMLElement[], string[]][]) => void,
 *  pointerLeave: (entries: [HTMLElement[], string[]][]) => void
 * }}
 */
const getListeners = (modifiers) => {
  let pointerEnter, pointerLeave;

  if (modifiers.not) {
    pointerEnter = removeClasses;
    pointerLeave = addClasses;
  } else {
    pointerEnter = addClasses;
    pointerLeave = removeClasses;
  }

  return { pointerEnter, pointerLeave };
};

/**
 * @returns void
 */
const createVHoverClass = () => {
  const createdStyle = !!document.getElementById("v-hover-style");
  if (createdStyle) return;

  const style = document.createElement("style");

  document.head.appendChild(style);

  style.id = "v-hover-style";
  style.sheet.insertRule(".v-hover { transition: 0.2s; }");
};

Vue.directive("hover", {
  /**
   *
   * @param {HTMLElement} el
   * @param {{
   *  value: Value,
   *  modifiers: Modifiers,
   *  arg: string
   * }} binding
   */
  bind: (el, binding) => {
    createVHoverClass();

    if (binding.arg === "reactive") {
      el.classList.add("v-hover");

      if (!binding.modifiers.not) {
        el.addEventListener(
          "pointerenter",
          () => (binding.value.active = true)
        );
        el.addEventListener(
          "pointerleave",
          () => (binding.value.active = false)
        );
      } else {
        el.addEventListener(
          "pointerenter",
          () => (binding.value.active = false)
        );
        el.addEventListener(
          "pointerleave",
          () => (binding.value.active = true)
        );
      }
    } else {
      const entries = getEntries(el, binding.value);

      entries.forEach(([collection]) =>
        collection.forEach((child) => child.classList.add("v-hover"))
      );

      const { pointerEnter, pointerLeave } = getListeners(binding.modifiers);

      el.addEventListener("pointerenter", () => pointerEnter(entries));
      el.addEventListener("pointerleave", () => pointerLeave(entries));
    }
  },
});
