<template>
  <v-text-field
    :rules="rules"
    v-bind="fieldProps"
    name="email"
    label="E-mail"
    type="email"
    autocomplete="email"
  ></v-text-field>
</template>

<script>
import { requiredValidation } from "../validations/requiredValidation";
import { emailValidation } from "../validations/emailValidation";
import { mixin } from "./mixin";

export default {
  data: () => ({
    rules: [requiredValidation("Digite seu e-mail."), emailValidation()],
  }),
  mixins: [mixin],
};
</script>
