<template>
  <v-container class="py-10 py-md-13 py-lg-16">
    <TitleWidget>{{ title }}</TitleWidget>

    <LoadingWidget v-if="loading" />

    <transition name="fade">
      <div v-if="!loading" class="mt-6 mt-md-8">
        <v-row v-if="items.length">
          <template v-for="(item, key) in items">
            <v-col v-if="item" :key="key" cols="12" sm="6" md="4" lg="3">
              <ProductCard :key="key" v-bind="item" />
            </v-col>
          </template>

          <v-col cols="12">
            <v-pagination
              class="mt-10"
              v-model="page"
              :length="pagesLength"
              :total-visible="9"
              v-if="pagesLength > 1"
            ></v-pagination>
          </v-col>
        </v-row>

        <v-row v-else>
          <v-col cols="12">
            <v-alert type="info">
              Nenhum conteúdo adicionado até o momento.
            </v-alert>
          </v-col>
        </v-row>
      </div>
    </transition>
  </v-container>
</template>

<script>
import LoadingWidget from "../components/widgets/LoadingWidget.vue";
import TitleWidget from "../components/widgets/TitleWidget.vue";
import ProductCard from "../components/cards/ProductCard.vue";
import { formatToURL } from "../utils/formatToURL";

const _config = {
  mod: "products",
  path: "/produtos",
  itemsPerPage: 24,
  returnTotalItems: 1,
};

export default {
  data: () => ({
    loading: true,
    items: [],
    totalItems: 0,
    subcategoryTitle: "",
  }),
  created() {
    this.$rest("productsSubcategories").get();

    this.getList();
  },
  computed: {
    subcategoryId() {
      return this.$route.params.subcategory.replace(/^.*-([0-9]+)$/, "$1");
    },
    page: {
      get() {
        const p = this.$route.query.p;
        return p ? parseInt(p) : 1;
      },
      set(v) {
        const query = { ...this.$route.query };
        query.p = v;

        return this.$router.push({ query });
      },
    },
    category() {
      return this.$rest("productsSubcategories")
        .list.map(({ id, category }) => ({
          subcategoryId: id,
          id: category.id,
          title: category.title,
        }))
        .find(({ subcategoryId }) => this.subcategoryId == subcategoryId);
    },
    pagesLength() {
      return Math.ceil(this.totalItems / _config.itemsPerPage);
    },
    title() {
      return this.category && this.subcategoryTitle
        ? `Produtos - ${this.subcategoryTitle}`
        : "Produtos";
    },
  },
  methods: {
    getList() {
      this.loading = true;

      const params = {
        page: this.page,
        itemsPerPage: _config.itemsPerPage,
        returnTotalItems: _config.returnTotalItems,
        subcategory: this.subcategoryId,
      };

      this.$rest(_config.mod)
        .get({ params })
        .then((data) => {
          if (_config.returnTotalItems) {
            this.subcategoryTitle = data.list[0].subcategory.title;
            this.items = data.list.map(({ id, img, title, subcategory }) => ({
              img,
              title,
              to: `${_config.path}/${formatToURL(this.category.title)}-${
                this.category.id
              }/${formatToURL(subcategory.title)}-${
                subcategory.id
              }/${formatToURL(title)}-${id}`,
            }));

            this.totalItems = data.totalItems;
          } else {
            this.subcategoryTitle = data[0].subcategory.title;
            this.items = data.map(({ id, img, title, subcategory }) => ({
              img,
              title,
              to: `${_config.path}/${formatToURL(this.category.title)}-${
                this.category.id
              }/${formatToURL(subcategory.title)}-${
                subcategory.id
              }/${formatToURL(title)}-${id}`,
            }));
          }
        })
        .finally(() => (this.loading = false));
    },
  },
  watch: {
    $route() {
      this.getList();
    },
  },
  components: {
    LoadingWidget,
    TitleWidget,
    ProductCard,
  },
};
</script>
