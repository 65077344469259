<template>
  <v-app-bar
    :class="{ 'header-bordered': $route.path !== '/esquadrias' }"
    color="white"
    elevate-on-scroll
    height="130px"
    app
  >
    <v-container class="py-0">
      <v-sheet
        width="100%"
        height="100%"
        color="transparent"
        class="d-flex justify-space-between align-center"
      >
        <router-link to="/esquadrias">
          <img
            v-if="informations.img"
            :src="files + informations.img"
            :alt="informations.name"
            class="d-block"
            height="80px"
          />
        </router-link>

        <div class="d-flex align-center">
          <div>
            <div
              v-for="({ text, to, dropdown }, keyMenu) in menu"
              :key="keyMenu"
              class="d-inline-flex"
            >
              <v-btn
                v-if="!dropdown"
                class="
                  grey--text
                  text-caption text-lg-body-1
                  font-weight-medium
                  px-2 px-lg-5
                  py-5
                  nav-link
                "
                :to="to"
                depressed
                tile
                text
              >
                {{ text }}
              </v-btn>

              <v-menu
                v-else
                content-class="white elevation-0 dropdown-mega-menu"
                offset-y
                eager
                tile
              >
                <template v-slot:activator="{ on, attrs, value }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    class="
                      text-caption text-lg-body-1
                      font-weight-medium
                      px-2 px-lg-5
                      py-5
                      nav-link
                    "
                    :class="{
                      'grey--text': !value,
                      'primary--text': value,
                    }"
                    active-class="nav-link-active"
                    depressed
                    tile
                    text
                  >
                    {{ text }}
                    <v-icon class="ml-3" size="12"> fa-caret-down </v-icon>
                  </v-btn>
                </template>

                <v-container class="py-0">
                  <v-row class="my-0">
                    <template v-for="{ id, img, text, to } in dropdown">
                      <v-col
                        :key="to"
                        class="pt-10 pb-16"
                        cols="12"
                        sm="6"
                        md="4"
                      >
                        <div class="pa-4 position-relative category">
                          <div class="d-flex justify-center category-title">
                            <span
                              class="
                                white
                                secondary--text
                                text-h5 text-uppercase
                                font-weight-black
                                px-6
                              "
                            >
                              {{ text }}
                            </span>
                          </div>

                          <img
                            v-if="img"
                            class="d-block category-img"
                            :src="files + img + '?resize=1&w=200'"
                            :alt="text"
                          />

                          <div class="subitems">
                            <v-list tile>
                              <template
                                v-for="{ title, path } in getSubitems(id)"
                              >
                                <v-list-item
                                  :key="path"
                                  class="grey--text font-weight-medium"
                                  v-hover="['div', 'primary--text']"
                                  :to="to + path"
                                >
                                  <v-list-item-title>
                                    {{ title }}
                                  </v-list-item-title>
                                </v-list-item>
                              </template>
                            </v-list>
                          </div>
                        </div>
                      </v-col>
                    </template>
                  </v-row>
                </v-container>
              </v-menu>
            </div>
          </div>
        </div>
      </v-sheet>
    </v-container>
  </v-app-bar>
</template>

<script>
import { formatToURL } from "../../../utils/formatToURL";

export default {
  props: {
    menu: {
      type: Array,
      default: () => [],
    },
  },
  created() {
    this.$rest("productsSubcategories").get();
  },
  computed: {
    subcategories() {
      return this.$rest("productsSubcategories").list.map(
        ({ id, title, category }) => ({
          id,
          title,
          path: `/${formatToURL(title)}-${id}`,
          category,
        })
      );
    },
    informations() {
      return this.$rest("informations").item;
    },
  },
  methods: {
    getSubitems(id) {
      return this.subcategories.filter(({ category }) => category.id === id);
    },
  },
};
</script>

<style lang="scss" scoped>
.header-bordered {
  &:before {
    background-color: #dddddd;
    bottom: 0;
    content: "";
    height: 1px;
    left: 0;
    position: absolute;
    right: 0;
  }
}

.nav-link {
  &:before {
    opacity: 0 !important;
  }
}
</style>

<style lang="scss">
.dropdown-mega-menu {
  left: 0 !important;
  right: 0 !important;
  top: 130px !important;
  max-width: 100% !important;

  .category {
    border: 6px solid #d9d9d9;

    &-title {
      left: 0;
      position: absolute;
      right: 0;
      top: -20px;
    }

    &-img {
      bottom: -40px;
      max-width: 35%;
      -o-object-fit: contain;
      object-fit: contain;
      position: absolute;
      right: -15px;
      z-index: 8;

      @media screen and (max-width: 1264px) {
        max-width: 30%;
      }
    }
  }
}
</style>
