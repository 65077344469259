<template>
  <div>
    <SlideShowSection />
    <PlannedFurnitureSection />
    <ProductsCategoriesSection />
  </div>
</template>

<script>
import SlideShowSection from "../components/sections/SlideShowSection.vue";
import PlannedFurnitureSection from "../components/sections/PlannedFurnitureSection.vue";
import ProductsCategoriesSection from "../components/sections/ProductsCategoriesSection.vue";

export default {
  components: {
    SlideShowSection,
    PlannedFurnitureSection,
    ProductsCategoriesSection,
  },
};
</script>
