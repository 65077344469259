<template>
  <v-container class="py-10 py-md-13 py-lg-16">
    <TitleWidget>Produtos</TitleWidget>

    <transition name="fade">
      <v-row v-if="items.length" class="mt-6 mt-md-8">
        <template v-for="(item, key) in items">
          <v-col v-if="item" :key="key" cols="12" sm="6" md="4">
            <ProductCategoryCard v-bind="item" />
          </v-col>
        </template>
      </v-row>
    </transition>
  </v-container>
</template>

<script>
import TitleWidget from "../components/widgets/TitleWidget.vue";
import ProductCategoryCard from "../components/cards/ProductCategoryCard.vue";
import { formatToURL } from "../utils/formatToURL";

const _config = {
  mod: "productsCategories",
  path: "/produtos",
};

export default {
  computed: {
    items() {
      return this.$rest(_config.mod).list.map(({ id, img, title }) => ({
        img,
        title,
        to: `${_config.path}/${formatToURL(title)}-${id}`,
      }));
    },
  },
  components: {
    TitleWidget,
    ProductCategoryCard,
  },
};
</script>
