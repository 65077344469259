<template>
  <div>
    <v-container class="py-10 py-md-13 py-lg-16">
      <v-row justify="center">
        <v-col cols="12">
          <TitleWidget>Fale Conosco</TitleWidget>

          <v-row justify="center" class="mt-6">
            <template v-for="(item, keyContact) in contacts">
              <v-col
                v-if="!Array.isArray(item)"
                :key="keyContact"
                cols="6"
                md="4"
                lg="3"
              >
                <v-card
                  class="justify-center text-center py-7"
                  :href="item.link"
                  :target="item.target"
                  height="100%"
                  outlined
                  flat
                >
                  <v-icon color="primary" :size="smAndDown ? '30px' : '40px'">
                    {{ item.icon }}
                  </v-icon>

                  <div class="text-caption text-md-body-1 grey--text px-4 mt-6">
                    {{ item.text }}
                  </div>
                </v-card>
              </v-col>

              <template v-else>
                <v-col
                  :key="keyContactItem"
                  v-for="({ text, link, target, icon }, keyContactItem) in item"
                  cols="6"
                  md="4"
                  lg="3"
                >
                  <v-card
                    class="justify-center text-center py-7"
                    :href="link"
                    :target="target"
                    height="100%"
                    outlined
                    flat
                  >
                    <v-icon color="primary" :size="smAndDown ? '30px' : '40px'">
                      {{ icon }}
                    </v-icon>

                    <div
                      class="text-caption text-md-body-1 grey--text px-4 mt-6"
                    >
                      {{ text }}
                    </div>
                  </v-card>
                </v-col>
              </template>
            </template>
          </v-row>
        </v-col>

        <v-col class="mt-8" cols="12" md="8" lg="7">
          <TitleWidget>Mande uma mensagem</TitleWidget>

          <ContactForm
            class="mt-8"
            :action="files + formAction"
            response-to="/fale-conosco/obrigado"
          />
        </v-col>
      </v-row>
    </v-container>

    <div
      v-if="informations.map"
      class="grey lighten-4 map"
      v-html="informations.map"
    ></div>
  </div>
</template>

<script>
import TitleWidget from "../components/widgets/TitleWidget.vue";
import ContactForm from "../components/forms/ContactForm.vue";
import { getContact } from "../utils/getContact";

export default {
  data: () => ({
    formAction: "/rest/email/contact-us",
  }),
  computed: {
    informations() {
      return this.$rest("informations").item;
    },
    contacts() {
      return getContact(this.informations);
    },
    smAndDown() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  components: {
    TitleWidget,
    ContactForm,
  },
};
</script>

<style lang="scss" scoped>
.map::v-deep {
  aspect-ratio: 1920 / 450;
  min-height: 350px;
  width: 100%;

  iframe {
    height: 100% !important;
    width: 100% !important;
  }
}
</style>
