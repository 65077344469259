<template>
  <footer class="shell-footer">
    <v-sheet class="position-relative background-texture">
      <v-container class="py-6">
        <div class="grid-list">
          <div class="grid-list-item">
            <router-link to="/esquadrias">
              <img
                v-if="informations.img"
                :src="files + informations.img"
                height="90px"
                class="d-block mx-auto mx-md-0"
              />
            </router-link>
          </div>

          <div class="d-flex flex-column align-start grid-list-item">
            <v-sheet
              width="100%"
              color="transparent"
              class="
                text-body-2 text-md-subtitle-2
                font-weight-bold
                primary--text
              "
            ></v-sheet>

            <div v-for="(item, keyContact) in contacts" :key="keyContact">
              <v-btn
                v-if="!Array.isArray(item)"
                class="primary--text text-body-2 text-none btn-contact"
                height="auto"
                min-height="36px"
                :href="item.link"
                :target="item.target"
                :title="item.name"
                depressed
                color="transparent"
              >
                <v-icon v-if="item.icon" :size="item.size" class="mr-2">
                  {{ item.icon }}
                </v-icon>

                <span v-html="item.text"></span>
              </v-btn>

              <div v-else class="d-flex flex-column align-start">
                <v-btn
                  v-for="(
                    { name, text, link, target, icon, size }, keyContactItem
                  ) in item"
                  :key="keyContactItem"
                  class="primary--text text-body-2 text-none btn-contact"
                  height="auto"
                  min-height="36px"
                  :href="link"
                  :target="target"
                  :title="name"
                  depressed
                  color="transparent"
                >
                  <v-icon v-if="icon" :size="size" class="mr-2">
                    {{ icon }}
                  </v-icon>

                  <span v-html="text"></span>
                </v-btn>
              </div>
            </div>

            <div class="d-flex flex-column align-start">
              <v-btn
                class="primary--text text-body-2 text-none btn-contact"
                height="auto"
                min-height="36px"
                to="/politica-de-privacidade"
                color="transparent"
                depressed
              >
                <v-icon :size="18" class="mr-2"> fa-shield </v-icon>

                Política de Privacidade
              </v-btn>
            </div>
          </div>

          <div class="grid-item">
            <div class="d-flex justify-center justify-sm-end align-center">
              <div
                v-for="({ text, link, icon, size }, keySocial) in social"
                :key="keySocial"
                class="d-inline-flex"
              >
                <v-btn
                  class="text-caption text-lg-body-1 primary--text ml-2"
                  :href="link"
                  target="_blank"
                  min-width="auto"
                  color="transparent"
                  small
                  :title="text"
                  depressed
                  rounded
                  fab
                >
                  <v-icon color="primary" :size="size">{{ icon }}</v-icon>
                </v-btn>
              </div>
            </div>
          </div>
        </div>
      </v-container>
    </v-sheet>

    <v-sheet color="primary">
      <v-container
        class="
          pt-0
          pb-5
          d-flex
          flex-column flex-sm-row
          justify-space-between
          align-center
          text-center
        "
      >
        <div class="white--text text-caption mt-5">
          Copyright ©
          <b class="font-weight-bold">{{ year }} {{ informations.name }}</b
          >.
          <span class="d-block d-sm-inline-block">
            Todos os direitos reservados.
          </span>
        </div>
        <a
          class="d-flex mt-5"
          href="https://www.mrxweb.com.br/"
          target="_blank"
        >
          <img
            class="shell-footer-logo"
            src="../../assets/img/mrxweb.svg"
            alt="MRX Web Sites, Publicidade, Marketing Digital, Produção de Vídeos - Sinop/MT"
            title="MRX Web Sites, Publicidade, Marketing Digital, Produção de Vídeos - Sinop/MT"
            height="20px"
          />
        </a>
      </v-container>
    </v-sheet>
  </footer>
</template>

<script>
import { getContact } from "../../utils/getContact";
import { getSocial } from "../../utils/getSocial";

export default {
  data: () => ({
    year: new Date().getFullYear(),
  }),
  computed: {
    informations() {
      return this.$rest("informations").item;
    },
    contacts() {
      return getContact(this.informations);
    },
    social() {
      return getSocial(this.informations);
    },
  },
};
</script>

<style lang="scss" scoped>
.shell-footer {
  .grid-list {
    display: grid;
    gap: 24px 48px;
    grid-template-columns: auto 1fr auto;
    justify-content: space-between;

    @media screen and (min-width: 600.001px) and (max-width: 960px) {
      gap: 24px;
      grid-template-columns: auto auto;

      &-item:first-child {
        grid-column-start: 1;
        grid-column-end: 3;
      }
    }

    @media screen and (max-width: 600px) {
      grid-template-columns: 1fr;
    }

    .btn-contact::v-deep {
      .v-btn__content {
        max-width: 100%;
        white-space: normal;
      }
    }
  }
}

.background-texture {
  background-image: url(../../assets/img/textura.jpg) !important;
  background-size: cover;
  @media (max-width: 960px) {
    background-image: url(../../assets/img/textura-media.jpg) !important;
    background-size: cover;
  }
}
</style>
