<template>
  <div
    class="position-relative slides"
    :class="{ 'has-nav': nav && pages > 1 }"
  >
    <hooper-container
      ref="hooperContainer"
      class="carousel-slides"
      :style="{ height }"
      :settings="hooperSettings"
      @slide="updateCarousel"
    >
      <template v-for="(item, i) in items">
        <hooper-slide :key="i" :class="spacing">
          <slot name="item" :item="item" :keyItem="i" />
        </hooper-slide>
      </template>
    </hooper-container>

    <div
      v-if="nav && pages > 1"
      class="
        d-flex
        justify-space-between
        px-3 px-md-4 px-lg-5
        carousel-slides-nav
      "
    >
      <a
        class="
          d-flex
          align-center
          justify-center
          pr-1
          cursor-pointer
          carousel-slides-nav-item
        "
        :class="{ 'carousel-slides-nav-item-active': activeNav.prev }"
        @click.prevent="slidePrev"
      >
        <v-icon :size="mobile ? 35 : 56"> fa-angle-left </v-icon>
      </a>

      <a
        class="
          d-flex
          align-center
          justify-center
          pl-1
          cursor-pointer
          carousel-slides-nav-item
        "
        :class="{ 'carousel-slides-nav-item-active': activeNav.next }"
        @click.prevent="slideNext"
      >
        <v-icon :size="mobile ? 35 : 56"> fa-angle-right </v-icon>
      </a>
    </div>

    <div
      v-if="dots && pages > 1"
      class="d-flex justify-center mt-6 mt-md-10 carousel-pagination"
    >
      <div
        v-for="i in pages"
        @click="slideTo(i)"
        v-ripple
        :class="['carousel-pagitation-dot', i == dotActive && 'active']"
        :key="i"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
    spacing: {
      type: String,
      default: "",
    },
    dots: {
      type: Boolean,
      default: false,
    },
    nav: {
      type: Boolean,
      default: false,
    },
    autoPlay: {
      type: Boolean,
      default: true,
    },
    infiniteScroll: {
      type: Boolean,
      default: true,
    },
    playSpeed: {
      type: Number,
      default: 2000,
    },
    height: {
      type: String,
      default: "auto",
    },
    itemsToShow: {
      type: Object,
      default: () => {
        return {
          xs: 2,
          sm: 4,
          md: 5,
          lg: 5,
          xl: 5,
        };
      },
    },
  },
  data() {
    return {
      dotActive: 1,
      carouselData: 0,
    };
  },
  computed: {
    pages() {
      return Math.ceil(this.items.length / this.breakpoints);
    },
    breakpoints() {
      return this.itemsToShow[this.$vuetify.breakpoint.name];
    },
    hooperSettings() {
      const thresholds = this.$vuetify.breakpoint.thresholds;

      return {
        itemsToShow: this.itemsToShow.xs,
        itemsToSlide: 1,
        hoverPause: true,
        wheelControl: false,
        trimWhiteSpace: true,
        playSpeed: this.playSpeed,
        infiniteScroll:
          !!this.infiniteScroll && this.items.length > this.itemsToShow.xs,
        autoPlay: !!this.autoPlay && this.items.length > this.itemsToShow.xs,
        mouseDrag: this.items.length > this.itemsToShow.xs,
        touchDrag: this.items.length > this.itemsToShow.xs,
        breakpoints: {
          [thresholds.xs]: {
            itemsToShow: this.itemsToShow.sm,
            infiniteScroll:
              !!this.infiniteScroll && this.items.length > this.itemsToShow.sm,
            autoPlay:
              !!this.autoPlay && this.items.length > this.itemsToShow.sm,
            mouseDrag: this.items.length > this.itemsToShow.sm,
            touchDrag: this.items.length > this.itemsToShow.sm,
          },
          [thresholds.sm]: {
            itemsToShow: this.itemsToShow.md,
            infiniteScroll:
              !!this.infiniteScroll && this.items.length > this.itemsToShow.md,
            autoPlay:
              !!this.autoPlay && this.items.length > this.itemsToShow.md,
            mouseDrag: this.items.length > this.itemsToShow.md,
            touchDrag: this.items.length > this.itemsToShow.md,
          },
          [thresholds.md]: {
            itemsToShow: this.itemsToShow.lg,
            infiniteScroll:
              !!this.infiniteScroll && this.items.length > this.itemsToShow.lg,
            autoPlay:
              !!this.autoPlay && this.items.length > this.itemsToShow.lg,
            mouseDrag: this.items.length > this.itemsToShow.lg,
            touchDrag: this.items.length > this.itemsToShow.lg,
          },
          [thresholds.lg]: {
            itemsToShow: this.itemsToShow.xl,
            infiniteScroll:
              !!this.infiniteScroll && this.items.length > this.itemsToShow.xl,
            autoPlay:
              !!this.autoPlay && this.items.length > this.itemsToShow.xl,
            mouseDrag: this.items.length > this.itemsToShow.xl,
            touchDrag: this.items.length > this.itemsToShow.xl,
          },
        },
      };
    },
    mobile() {
      return this.$vuetify.breakpoint.xsOnly;
    },
    activeNav() {
      return {
        prev: this.carouselData > 0,
        next: this.carouselData < (this.items.length - 1) / this.breakpoints,
      };
    },
  },
  methods: {
    slidePrev() {
      this.$refs.hooperContainer.slidePrev();
    },
    slideNext() {
      this.$refs.hooperContainer.slideNext();
    },
    updateCarousel(payload) {
      this.carouselData =
        payload.currentSlide >= 0 ? payload.currentSlide : this.items.length;
    },
    setDotFromSlideIndex(slideIndex) {
      slideIndex++;
      const slide =
        slideIndex <= this.items.length && slideIndex > 0 ? slideIndex : 1;

      const doActive = Math.ceil(slide / this.breakpoints);

      this.dotActive =
        doActive < 0 ? 0 : doActive > this.pages ? this.pages : doActive;
    },
    slideTo(dotIndex) {
      const slideIndex = Math.floor((dotIndex - 1) * this.breakpoints);

      this.$refs.hooperContainer.slideTo(slideIndex);
      this.$refs.hooperContainer.restartTimer();
    },
  },
  watch: {
    carouselData() {
      this.$refs.hooperContainer.slideTo(this.carouselData);

      this.setDotFromSlideIndex(this.carouselData);
    },
    breakpoints() {
      this.$refs.hooperContainer.slideTo(0);
      this.$refs.hooperContainer.restartTimer();
    },
  },
};
</script>

<style lang="scss" scoped>
.carousel-slides::v-deep {
  ul,
  ol {
    padding-left: 0 !important;
  }
}

.slides {
  &.has-nav {
    @media screen and (max-width: 1264px) {
      padding-left: 40px;
      padding-right: 40px;
    }
  }
}

.carousel-slides {
  &-nav {
    height: 0;
    left: -100px;
    margin-top: -40px;
    position: absolute;
    right: -100px;
    top: 50%;

    @media screen and (max-width: 1264px) {
      left: 0;
      right: 0;
    }

    &-item::v-deep {
      border-radius: 50%;
      height: 40px;
      transition: all 0.1s linear;
      width: 40px;

      .v-icon {
        color: var(--v-secondary-base) !important;
      }

      &:not(.carousel-slides-nav-item-active) {
        filter: grayscale(100%);
      }
    }
  }
}

.carousel-pagination {
  gap: 8px;

  .carousel-pagitation-dot {
    background-color: #e7e7e7;
    border-radius: 50%;
    cursor: pointer;
    height: 10px;
    transition: 0.3s;
    width: 10px;

    &.active {
      background-color: var(--v-secondary-base);
    }
  }
}
</style>
