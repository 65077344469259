<template>
  <div class="whatsapp-button">
    <TooltipWidget tip="WhatsApp" left>
      <v-btn :href="link" target="_blank" color="#25D366" fab>
        <v-icon size="29" color="white">fab fa-whatsapp</v-icon>
      </v-btn>
    </TooltipWidget>
  </div>
</template>

<script>
import TooltipWidget from "./TooltipWidget.vue";

export default {
  props: {
    link: {
      type: String,
      required: true,
    },
  },
  components: {
    TooltipWidget,
  },
};
</script>

<style>
.whatsapp-button {
  position: fixed;
  right: 16px;
  bottom: 16px;
  width: max-content;
  z-index: 9;
}
</style>
