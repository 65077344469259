<template>
  <div
    class="align-end position-relative item"
    :class="{ 'item-right': right }"
  >
    <img
      class="d-block px-6 px-md-0 mb-md-14 item-img"
      :src="files + img + '?resize=1&w=600'"
      :alt="title"
      width="100%"
    />

    <div
      class="
        px-6 px-md-4 px-lg-10
        pb-10 pb-md-16
        position-relative
        item-content
      "
    >
      <div
        class="
          d-flex
          justify-center
          text-center
          px-6 px-sm-10 px-md-16
          position-relative
          item-title
        "
      >
        <span
          class="
            white
            secondary--text
            text-h5 text-md-h4 text-lg-h3 text-uppercase
            font-weight-black
            px-6
            mt-6 mt-md-0
            position-relative
          "
        >
          {{ title }}
        </span>
      </div>

      <div
        class="grey--text text-body-2 text-md-body-1 text-center mt-4 mt-md-0"
      >
        {{ text }}
      </div>

      <ButtonWidget class="mt-8" :to="to" align="center" tile>
        VER MAIS
      </ButtonWidget>
    </div>
  </div>
</template>

<script>
import ButtonWidget from "../widgets/ButtonWidget.vue";

export default {
  props: {
    img: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    to: {
      type: String,
      required: true,
    },
    right: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ButtonWidget,
  },
};
</script>

<style lang="scss" scoped>
.item {
  display: grid;
  gap: 0;
  grid-template-columns: 55% 45%;

  @media screen and (max-width: 960px) {
    grid-template-columns: 1fr;
  }

  &-img {
    aspect-ratio: 650 / 430;
    -o-object-fit: cover;
    object-fit: cover;
    z-index: 2;
  }

  &-content {
    &:before {
      border: 6px solid #e3e3e3;
      bottom: 0;
      content: "";
      left: -160px;
      pointer-events: none;
      position: absolute;
      right: 0;
      top: 0;

      @media screen and (max-width: 960px) {
        left: 0;
        top: -160px;
      }
    }
  }

  &-title {
    position: relative;
    transform: translate(0, -50%);

    @media screen and (max-width: 960px) {
      transform: translate(0, 0);
    }
  }

  @media screen and (min-width: 960.001px) {
    &.item-right {
      grid-template-columns: 45% 55%;

      .item-img {
        order: 12;
      }

      .item-content {
        order: 0;

        &:before {
          left: 0;
          right: -160px;
        }
      }
    }
  }
}
</style>
