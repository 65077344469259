<template>
  <PageTemplate>
    <div class="text-h4 text-md-h3 text-center">Política de Privacidade</div>
    <PolicyPrivacyWidget class="mt-10" />
  </PageTemplate>
</template>

<script>
import PageTemplate from "../components/templates/PageTemplate.vue";
import PolicyPrivacyWidget from "../components/widgets/PolicyPrivacyWidget.vue";

export default {
  computed: {
    informations() {
      return this.$rest("informations").item;
    },
  },
  components: {
    PageTemplate,
    PolicyPrivacyWidget,
  },
};
</script>

<style></style>
