<template>
  <div>
    <LoadingWidget v-if="loading" />

    <transition name="fade">
      <v-container
        v-if="!loading && item.title"
        class="py-10 py-md-13 py-lg-16"
      >
        <v-breadcrumbs class="pa-1 mb-4" :items="breadcrumbItems">
          <template #divider>
            <v-icon size="10">fas fa-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>

        <v-card :color="xsOnly ? 'transparent' : ''" flat tile>
          <v-row align="center" justify="center">
            <v-col cols="12" sm="6">
              <div
                v-if="xsOnly"
                class="text-h6 font-weight-bold text-center mb-6"
              >
                {{ item.title }}
              </div>

              <v-sheet
                class="mb-6 mb-md-0 product-item-page_images"
                color="transparent"
              >
                <div class="position-relative">
                  <zoom-on-hover
                    v-if="imgFeatured.img"
                    :img-normal="`${imgFeatured.img}?resize=1&w=500`"
                    :img-zoom="`${imgFeatured.img}?resize=1&w=800`"
                    :scale="1.2"
                    class="
                      d-flex
                      align-center
                      justify-center
                      product-item-page_image-highlight
                    "
                  />

                  <div
                    v-if="imgFeatured.title"
                    class="text-center product-item-page_images-title"
                  >
                    <div
                      class="white grey--text text--darken-4 text-body-1 pa-4"
                    >
                      {{ imgFeatured.title }}
                    </div>
                  </div>
                </div>

                <div v-if="imgList" class="mt-4 mt-md-0 justify-md-end">
                  <hooper-container
                    :settings="hooperSettings"
                    class="product-item-page_carousel"
                  >
                    <hooper-slide v-for="(imgItem, key) in imgList" :key="key">
                      <div
                        @mouseover="imgFeatured = imgItem"
                        v-ripple
                        :class="
                          `pa-0 product-item-page_image-thumbnail` +
                          (imgFeatured.img === imgItem.img ? ` active` : ``)
                        "
                        tile
                        depressed
                      >
                        <img
                          :src="`${imgItem.img}?resize=1&w=65`"
                          :alt="imgItem.title"
                          class="product-item-page_image"
                        />
                      </div>
                    </hooper-slide>
                  </hooper-container>
                </div>
              </v-sheet>
            </v-col>

            <v-col
              cols="12"
              sm="6"
              class="mb-0 mb-sm-16 mb-md-0 pb-0 pb-sm-16 pb-md-0"
            >
              <div
                v-if="!xsOnly"
                class="
                  text-h6 text-md-h5 text-lg-h4
                  font-weight-bold
                  text-center
                "
              >
                {{ item.title }}
              </div>

              <div class="d-flex justify-center mt-4">
                <v-btn
                  :href="informations.whatsapp_float"
                  target="_blank"
                  color="primary"
                  height="auto"
                  class="
                    text-body-1
                    font-weight-bold
                    text-none
                    mb-5 mb-sm-0
                    mt-sm-7
                    py-3
                    px-7 px-md-10 px-lg-16
                  "
                  depressed
                  rounded
                >
                  <v-icon size="28" class="mr-3"> fa-whatsapp </v-icon>
                  SOLICITAR ORÇAMENTO
                </v-btn>
              </div>
            </v-col>
          </v-row>

          <template v-if="item.text">
            <div class="pt-8">
              <v-divider class="mb-6" />
              <div
                class="
                  text-h6 text-md-h5
                  grey--text
                  text--darken-4
                  font-weight-bold
                "
              >
                Descrição
              </div>
              <div
                class="grey--text text--darken-3 text-body-2 mt-3 mt-md-4"
                v-html="item.text"
              ></div>
            </div>
          </template>

          <template v-if="item.files.list.length">
            <div class="pt-8">
              <v-divider class="mb-6" />
              <div
                class="
                  text-h6 text-md-h5
                  grey--text
                  text--darken-4
                  font-weight-bold
                "
              >
                Anexos
              </div>

              <v-simple-table class="mt-4">
                <template #default>
                  <tbody>
                    <tr
                      @click="openTab(files + path)"
                      class="cursor-pointer"
                      v-for="{ path, title } in item.files.list"
                      :key="path"
                    >
                      <td>
                        <v-icon class="mt-n1" color="grey" left size="20">
                          fa-file-pdf
                        </v-icon>

                        <span class="d-inline-block mt-1">
                          {{ title }}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </div>
          </template>
        </v-card>
      </v-container>
    </transition>
  </div>
</template>

<script>
import LoadingWidget from "../components/widgets/LoadingWidget.vue";
import { formatToURL } from "../utils/formatToURL";

const _config = {
  mod: "products",
};

export default {
  data: () => ({
    loading: false,
    imgFeatured: null,
    imgList: [],
    hooperSettings: {
      itemsToShow: 3,
      vertical: false,
      breakpoints: {
        360: {
          itemsToShow: 4,
          vertical: false,
        },
        450: {
          itemsToShow: 5,
          vertical: false,
        },
        600: {
          itemsToShow: 3,
          vertical: false,
        },
        700: {
          itemsToShow: 4,
          vertical: false,
        },
        960: {
          itemsToShow: 4,
          vertical: true,
        },
        1200: {
          itemsToShow: 5,
          vertical: true,
        },
      },
    },
  }),
  created() {
    this.getItem();
  },
  computed: {
    informations() {
      return this.$rest("informations").item;
    },
    item() {
      return this.$rest(_config.mod).item;
    },
    subcategoryId() {
      return this.$route.params.subcategory.replace(/^.*-([0-9]+)$/, "$1");
    },
    itemId() {
      return this.$route.params.item.replace(/^.*-([0-9]+)$/, "$1");
    },
    xsOnly() {
      return this.$vuetify.breakpoint.xsOnly;
    },
    breadcrumbItems() {
      const items = [];

      if (!this.xsOnly) {
        items.push({
          text: "Início",
          to: "/",
          disabled: false,
        });
      }

      items.push({
        text: "Produtos",
        to: "/produtos",
        exact: true,
        disabled: false,
      });

      const category = this.$rest("productsSubcategories")
        .list.map(({ id, title, category }) => ({
          subcategory: {
            id,
            title,
          },
          category: {
            id: category.id,
            title: category.title,
          },
        }))
        .find(({ subcategory }) => this.subcategoryId == subcategory.id);

      if (category) {
        items.push({
          text: category.category.title,
          to: `/produtos/${formatToURL(category.category.title)}-${
            category.category.id
          }`,
          exact: true,
          disabled: false,
        });

        items.push({
          text: category.subcategory.title,
          to: `/produtos/${formatToURL(category.category.title)}-${
            category.category.id
          }/${formatToURL(category.subcategory.title)}-${
            category.subcategory.id
          }`,
          exact: true,
          disabled: false,
        });
      }

      if (this.item.title && !this.xsOnly) {
        items.push({
          text: this.item.title,
          disabled: true,
        });
      }

      return items;
    },
  },
  methods: {
    openTab(v) {
      window.open(v, "_blank").focus();
    },
    getItem() {
      this.loading = true;

      this.$rest(_config.mod)
        .get({ id: this.itemId })
        .then((data) => {
          if (data.img?.featured?.path) {
            this.imgFeatured = {
              img: this.files + data.img.featured.path,
              title: data.img.featured.title,
            };

            this.imgList = data.img.list.map(({ path, title }) => {
              return {
                img: path ? this.files + path : "",
                title,
              };
            });
          }
        })
        .finally(() => (this.loading = false))
        .catch(() => this.$router.replace("/error404"));
    },
  },
  watch: {
    itemId() {
      this.getItem();
    },
  },
  components: {
    LoadingWidget,
  },
};
</script>

<style lang="scss">
.product-item-page_image-highlight {
  border: 1px solid #ddd !important;
  background-color: white;
  padding: 20px;
  width: 100%;

  .normal {
    aspect-ratio: 1 / 1;
    display: block;
    object-fit: contain;
    -o-object-fit: contain;
    width: 100%;
  }
}
.product-item-page_images {
  display: grid;
  grid-template-columns: auto 100px;

  @media screen and (max-width: 960px) {
    display: flex;
    flex-direction: column;
  }

  .product-item-page_images-title {
    bottom: 1px;
    left: 1px;
    position: absolute;
    right: 1px;
    z-index: 2;
  }

  .product-item-page_image {
    height: 76px;
    width: 76px;
    object-fit: contain;
    object-position: center;
    width: 100%;
    cursor: pointer;
  }

  .product-item-page_carousel {
    height: 100%;
    width: 100%;

    .product-item-page_image-thumbnail {
      border: 1px solid #ddd;
      height: 78px;
      width: 76px;

      &.active {
        border: 1px solid var(--v-primary-base) !important;
      }
    }
  }
}
</style>
